import React from 'react'

const strokeStyle = {vectorEffect: 'non-scaling-stroke'}

const Elephant = () => (
  <svg
    viewBox='0 0 100 100'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    preserveAspectRatio='xMidYMid'
    width='1em'
    height='1em'
  >

    <path d='M28.9 33.6c2.6.7 1.5 4.8-1.2 4.1-2.6-.8-1.4-4.9 1.2-4.1zm1.9 15.5c.9-1 .8-2.5-.2-3.4-.5-.5-1-.7-1.6-.7-.7 0-1.3.3-1.8.8-5.9 6.5-14 10.4-22.8 11C3 56.9 2 58 2.1 59.4c.1 1.3 1.2 2.3 2.4 2.3h.2C14.8 61 24 56.5 30.8 49.1zm67 7.6c-.3 1.1-1.3 1.7-2.3 1.7-.2 0-.5 0-.7-.1-2.2-.6-4.2-1.7-5.9-3.1v31.3c0 1.3-1.1 2.4-2.4 2.4H69.6c-1.3 0-2.4-1.1-2.4-2.4V74.8c-2.1.6-4.2.9-6.4.9s-4.3-.3-6.4-.9v11.7c0 1.3-1.1 2.4-2.4 2.4H35.2c-1.3 0-2.4-1.1-2.4-2.4v-29c-2-.5-4-1.2-5.9-2.1-.1 0-.1-.1-.2-.1 1.4-1.1 2.7-2.2 4-3.5 2.2.9 4.6 1.4 7.1 1.4 5 0 9.6-1.9 13.2-5.4 3.5-3.5 5.5-8.2 5.5-13.2s-1.9-9.7-5.4-13.2c-3.5-3.5-8.2-5.5-13.2-5.5s-9.6 1.9-13.2 5.4c-3.5 3.5-5.5 8.2-5.5 13.2v15.8c-1.6 1-3.2 1.8-4.9 2.5V34.5c0-6.3 2.5-12.2 6.9-16.6 4.4-4.4 10.3-6.9 16.6-6.9 6.3 0 12.2 2.5 16.6 6.9 1.1 1.1 2 2.2 2.8 3.4h8c13 0 23.5 10.5 23.5 23.5V48c1.7 2.7 4.3 4.7 7.3 5.6 1.4.4 2.2 1.8 1.8 3.1zM83.9 44.9c0-10.3-8.4-18.6-18.6-18.6h-5.5c1 2.6 1.5 5.4 1.5 8.3 0 6.3-2.5 12.2-6.9 16.6C50 55.6 44.1 58 37.8 58h-.1v26.1h11.9V72.8c-1.1-.6-2.2-1.4-3.2-2.3-1-.9-1.1-2.4-.3-3.4.9-1 2.4-1.1 3.4-.3 6.2 5.4 16.4 5.4 22.7 0 1-.9 2.6-.8 3.4.3.9 1 .8 2.6-.3 3.4-1 .9-2.1 1.6-3.2 2.3v11.3H84l-.1-39.2zm-63 26.3c-.5 0-.9-.2-1.2-.5-.3-.3-.5-.7-.5-1.2v-9.7c-1.6.7-3.2 1.4-4.9 1.9v7.8c0 1.8.7 3.4 1.9 4.7 1.2 1.2 2.9 1.9 4.7 1.9 1.3 0 2.4-1.1 2.4-2.4 0-1.4-1.1-2.5-2.4-2.5z' style={strokeStyle} stroke='transparent' />

  </svg>
)

export default Elephant
