import React from 'react'

const strokeStyle = {vectorEffect: 'non-scaling-stroke'}

const FhaLoan = () => (
  <svg
    viewBox='0 0 64 64'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    preserveAspectRatio='xMidYMid'
    width='1em'
    height='1em'
  >

    <path d='M32 39c1.7 0 3 1.3 3 3h2c0-1.6-.8-3.1-2-4v-3h-2v2.1c-.3-.1-.7-.1-1-.1h-1v-2h-2v2h-2v5c0 2.8 2.2 5 5 5 1.7 0 3 1.3 3 3v3h-3c-1.7 0-3-1.3-3-3h-2c0 1.6.8 3.1 2 4v3h2v-2.1c.3.1.7.1 1 .1h1v2h2v-2h2v-5c0-2.8-2.2-5-5-5-1.7 0-3-1.3-3-3v-3h3zM38.4 55.2l1.2 1.6c.8-.6 1.6-1.4 2.2-2.2l-1.6-1.2c-.5.7-1.1 1.3-1.8 1.8zM41.8 41.4l-1.6 1.2c1.2 1.5 1.8 3.5 1.8 5.4 0 1.3-.3 2.5-.8 3.6l1.8.8c.7-1.4 1-2.9 1-4.4 0-2.4-.8-4.7-2.2-6.6z' style={strokeStyle} stroke='transparent' />
    <path d='M61.2 19.3l-25-17c-2.5-1.7-5.9-1.7-8.4 0L13 12.3V9h2V3H3v6h2v8.8l-2.2 1.5c-1.1.7-1.8 2-1.8 3.3v.4c0 1.9 1.3 3.4 3 3.9V59h16.8c2.7 2.5 6.2 4 10.2 4h2c3.9 0 7.5-1.5 10.2-4H60V26.8c1.7-.5 3-2 3-3.9v-.3c0-1.3-.7-2.6-1.8-3.3zM5 5h8v2H5V5zm2 4h4v4.7l-4 2.7V9zm26 52h-2c-7.2 0-13-5.8-13-13 0-3 1-5.9 2.9-8.2l5.5-6.8h11.1l5.5 6.8c2 2.3 3 5.2 3 8.2 0 7.2-5.8 13-13 13zm4.5-30h-11l-5.1-6.8c-.3-.3-.4-.8-.4-1.2 0-1.1.9-2 2-2 .5 0 .8.5 1.4 1.5.6 1.1 1.4 2.5 3.1 2.5 1.7 0 2.5-1.5 3.1-2.5.6-1 .9-1.5 1.4-1.5s.8.5 1.4 1.5c.6 1.1 1.4 2.5 3.1 2.5 1.7 0 2.5-1.5 3.1-2.5.6-1 .9-1.5 1.4-1.5 1.1 0 2 .9 2 2 0 .4-.1.9-.4 1.2L37.5 31zM45 57c1.9-2.5 3-5.6 3-9 0-3.5-1.2-6.8-3.4-9.5L39.3 32l4.9-6.6c.5-.7.8-1.5.8-2.4 0-2.2-1.8-4-4-4-1.7 0-2.5 1.5-3.1 2.5-.6 1-.9 1.5-1.4 1.5-.5 0-.8-.5-1.4-1.5-.6-1-1.4-2.5-3.1-2.5s-2.5 1.5-3.1 2.5c-.6 1-.9 1.5-1.4 1.5-.5 0-.8-.5-1.4-1.5-.6-1-1.4-2.5-3.1-2.5-2.2 0-4 1.8-4 4 0 .9.3 1.7.8 2.4l4.9 6.6-5.3 6.5c-2.2 2.7-3.4 6-3.4 9.5 0 3.4 1.1 6.5 3 9H6V26.9c.5-.1.9-.3 1.4-.6L32 9.2l24.6 17.1c.4.3.9.5 1.4.6V57H45zm16-34c0 1.1-.9 2-2 2-.4 0-.8-.1-1.2-.4L32.4 7h-.8L6.2 24.6c-.3.3-.7.4-1.2.4-1.1 0-2-.9-2-2v-.3c0-.7.3-1.3.9-1.7l25-17c1.8-1.2 4.4-1.2 6.2 0l25 17c.6.4.9 1 .9 1.7v.3z' style={strokeStyle} stroke='transparent' />
    <path d='M54 53h2v2h-2zM54 49h2v2h-2zM54 45h2v2h-2zM8 28h2v2H8zM8 32h2v2H8zM8 36h2v2H8z' style={strokeStyle} stroke='transparent' />

  </svg>
)

export default FhaLoan
