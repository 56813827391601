import React from 'react'

const strokeStyle = {vectorEffect: 'non-scaling-stroke'}

const UsdaLoan = () => (
  <svg
    viewBox='0 0 64 64'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    preserveAspectRatio='xMidYMid'
    width='1em'
    height='1em'
  >

    <path d='M31 27.8v7.5l8.6 5 8.6-5v-9.9l-8.6-5-6.6 3.8v-7.5l-8.6-5-8.6 5v9.9l8.6 5 6.6-3.8zm2 0l5.5 3.2v6.3L33 34.2v-6.4zm7.6 9.5V31l5.5-3.2v6.3l-5.5 3.2zM45 26l-5.5 3.2-5.4-3.2 5.5-3.2L45 26zm-14-.6l-5.5 3.2v-6.3l5.5-3.2v6.3zm-1.1-8.1l-5.5 3.2-5.4-3.2 5.5-3.2 5.4 3.2zm-12 1.8l5.5 3.2v6.3l-5.5-3.2v-6.3zM37.9 51.7c-.4-.5-1.1-.8-1.7-.7h-1.1v5.9h1c.4 0 .8-.1 1.1-.2.3-.1.5-.4.7-.6.2-.3.3-.6.4-.9.1-.4.1-.8.1-1.1 0-.4 0-.9-.1-1.3 0-.5-.2-.8-.4-1.1zM43.7 54.9h1.7l-.8-3.3z' style={strokeStyle} stroke='transparent' />
    <path d='M55 44.2V16.7l-.3-.3-15-14.9-.3-.3H13.2C10.9 1.2 9 3.1 9 5.4v35.2c-.6.4-1 1.1-1 1.8v15.2c0 2.9 2.3 5.2 5.2 5.2h37.6c2.9 0 5.2-2.3 5.2-5.2V46c0-.8-.4-1.5-1-1.8zM23.8 55c.1 1-.3 2-.9 2.7-.7.7-1.7 1-2.7.9-1 .1-1.9-.3-2.7-.9-.7-.7-1-1.7-.9-2.6v-5.9h2V55c-.1.5.1 1.1.4 1.5.3.3.8.5 1.2.5.3 0 .5 0 .8-.1.2-.1.4-.2.5-.3.3-.5.4-1 .4-1.5v-5.8h2V55zm7.2 3c-.7.5-1.6.8-2.5.7-.7 0-1.4-.2-2-.5-.3-.2-.6-.4-.9-.7-.3-.3-.5-.7-.7-1.1l1.7-.7.3.6c.1.2.2.3.4.5.2.1.3.2.5.3.2.1.4.1.7.1.2 0 .3 0 .5-.1.2 0 .3-.1.4-.2l.3-.3c.1-.2.1-.3.1-.5 0-.3-.2-.6-.5-.8-.4-.3-.8-.5-1.3-.6-.8-.3-1.5-.6-2.2-1.1-.5-.4-.8-1.1-.8-1.8s.3-1.4.9-1.8c.6-.5 1.4-.8 2.2-.7.7 0 1.4.2 2 .5.3.2.6.4.8.7.3.3.5.7.6 1.1l-1.8.6c-.1-.3-.3-.6-.6-.9-.3-.2-.6-.4-1-.3h-.4c-.1 0-.3.1-.4.1-.2.1-.4.4-.4.7 0 .1 0 .3.1.4.1.1.2.3.4.3.4.2.9.4 1.4.6.7.2 1.3.6 1.9 1 .6.5.9 1.2.9 1.9.3.6 0 1.4-.6 2zm9.2-2.1c-.2.5-.4 1-.8 1.5-.8.8-2 1.3-3.2 1.2h-3.1v-9.3h3c.7 0 1.3.1 1.9.3.5.2 1 .5 1.3.9.4.4.6.9.8 1.5.2.6.3 1.3.2 1.9.2.7.1 1.3-.1 2zm6.2 2.7l-.5-2h-2.6l-.5 2h-2l2.7-9.3h2.4l2.7 9.3h-2.2zm6.5-14.7H30L27.5 41c-.4-.5-1-.7-1.6-.7H11.1V5.4c0-1.2.9-2.1 2.1-2.1H38V14c0 2.3 1.9 4.2 4.2 4.2h10.7v25.7z' style={strokeStyle} stroke='transparent' />

  </svg>
)

export default UsdaLoan
